@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-white;
  }
  h1 {
    font-size: 56px; /* Mobile */
    @apply md:text-h1;
  }
  h2 {
    font-size: 52px; /* Mobile (between h3 and h1) */
    @apply md:text-h2;
  }
  h3 {
    font-size: 32px; /* Mobile */
    @apply md:text-h3;
  }
  h4 {
    font-size: 28px; /* Mobile */
    @apply md:text-h4;
  }
  p,
  input,
  textarea {
    @apply text-p;
  }
  img {
    @apply select-none;
  }
}
